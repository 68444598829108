/* Report.css */
.chart-item {
  position: relative;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the item fills the grid tile */
}

.chart-item.dragging {
  /* background-color: lightblue; */
}

/* Add a class for the grid cell being dragged */
.react-grid-item.react-grid-placeholder {
  background-color: lightblue !important;
}

/* Top bar for the chart item */
.chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #007bff;
  color: #fff;
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 14px;
  min-height: 34px;
}

/* Buttons on the top bar */
.chart-actions button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 8px;
}

.generate-pdf-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto; /* Align to the right */
  margin-right: 10px; /* Add some space from the right */
}

.add-chart-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto; /* Align to the right */
}

.dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart-toolbar {
  position: relative;
  display: flex;
  align-items: center;
  visibility: hidden; /* Hide by default */
}

.chart-item:hover .chart-toolbar {
  visibility: visible; /* Show on hover */
}

.chart-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chart-menu button {
  background: none;
  border: none;
  padding: 12px 16px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.chart-menu button:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

.chart-menu button svg {
  flex-shrink: 0;
}

.chart-toolbar button {
  /* color: lightgray; */
  border: none;
  /* padding: 5px; */
  cursor: pointer;
  border-radius: 3px;
  background-color: transparent;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.chart-toolbar button:hover {
  color: lightgray;
}

.chart-toolbar button:hover svg {
  fill: white;
}

.modal {
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 10px;
  z-index: 1000; /* Ensure it's above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-button.small-round {
  width: 24px;
  height: 24px;
  border: 1px solid gray;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  color: darkgray
}

.close-button.small-round:hover {
  background-color: lightgray;
}

.side-panel-rpt {
  
  width: 100%; /* Take up the whole main part of the screen */
  height: 100%;
  background-color: #f9f9f9;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.side-panel-content {
  padding: 20px;
}

.side-panel h2 {
  margin-top: 0;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.side-panel input,
.side-panel textarea,
.side-panel select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #333;
}

.side-panel button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.side-panel button:hover {
  background-color: #0056b3;
}

.side-panel .cancel-button {
  background-color: #6c757d;
  margin-left: 10px;
}

.side-panel .cancel-button:hover {
  background-color: #5a6268;
}

.side-panel .button-group {
  display: flex;
  justify-content: flex-end;
}

.chart-container {
  flex: 1;
  position: relative;
  height: 100%; /* Ensure the container fills the parent */
}

nav {
  margin-bottom: 20px;
}

nav button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

nav button:hover {
  background-color: #0056b3;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 5px;
}

.dropdownRpt {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #ddd;
}

.page-button {
  display: block;
  margin-bottom: 5px;
  padding: 8px 12px;
  border: none;
  background-color: #f4f4f4;
  cursor: pointer;
  text-align: left;
}

.page-button:hover {
  background-color: #eaeaea;
}

.page-button.active {
  background-color: #eaeaea;
  border-left: 4px solid #007bff;
}

nav a {
  margin-right: 10px;
  text-decoration: none;
  color: #007bff;
}

nav a.active {
  font-weight: bold;
  text-decoration: underline;
}
