@import 'components/global.scss';

html {

  height: 100%;
  font-size: 62.5%;

}

#root {

  height: 100%;

}

body {

  color: $c-text;
  height: 100%;
  font-size: 1.4rem;
  font-family: 'Source Sans Pro', helvetica, arial, sans-serif;

  &.color {

    background-color: $c-blue;
    background-attachment: fixed;
    background-image: radial-gradient(closest-corner, $c-blue, $c-darkblue);

  }

  &.disableOverflow {

    overflow-y: hidden;

  }

  @media (min-width: $mobile){

    font-size: 1.5rem;

  }

  @media (min-width: 1200px){

    font-size: 1.6rem;

  }
}
